export const dictionary: string[] = `ad
ah
as
at
be
by
do
go
he
hi
if
in
it
me
my
no
of
oh
on
or
so
to
up
us
we
act
add
age
ago
aid
aim
air
all
and
any
app
arm
art
ask
bad
bag
ban
bar
bed
bee
beg
bet
big
bin
bit
box
boy
bus
but
buy
bye
can
cap
car
cat
cow
cry
cup
cut
dad
day
die
dig
dog
adv
dry
due
ear
eat
egg
end
eye
fan
far
fat
fee
few
fit
fix
flu
fly
for
fry
fun
fur
gap
gas
get
god
gun
guy
gym
hat
her
hey
him
his
hit
hot
how
ice
ill
its
jam
job
joy
key
kid
lab
law
lay
leg
let
lie
adj
lip
lot
low
mad
man
map
may
mix
mud
mum
net
new
nor
not
now
nut
odd
off
oil
old
one
our
out
owe
own
pan
pay
pen
per
pet
pig
pin
pop
pot
pub
put
raw
red
rid
row
rub
run
sad
say
sea
see
set
sex
she
shy
sir
sit
six
ski
sky
son
sum
sun
tax
tea
ten
the
tie
tin
tip
toe
too
top
toy
try
two
use
van
via
war
way
web
wet
who
why
win
wow
yes
yet
you
ash
bat
bay
bid
bow
bug
cop
cue
dam
dip
dot
dub
duo
ego
era
gay
gig
gut
hip
ink
jet
kit
lad
lap
log
mob
nod
non
opt
pad
pit
rat
ray
rip
rob
rod
sin
spy
sue
tag
tap
ton
vow
wit
able
aged
also
area
arms
army
aunt
away
baby
back
bake
ball
band
bank
base
bath
bean
bear
beat
beef
beer
bell
belt
bend
bent
best
bike
bill
bird
bite
blog
blow
blue
boat
body
boil
bomb
bond
bone
book
boot
born
boss
both
bowl
burn
bury
bush
busy
cafe
cake
call
calm
camp
card
care
case
cash
cast
cell
cent
chat
chef
chip
cite
city
club
clue
coal
coat
code
coin
cold
come
cook
cool
copy
core
cost
crew
crop
cure
dark
data
date
dead
deal
dear
debt
deep
deny
desk
diet
dirt
disc
dish
door
down
drag
draw
drop
drug
drum
dust
duty
each
earn
east
easy
edge
edit
else
euro
even
ever
evil
exam
face
fact
fail
fair
fall
farm
fast
fear
feed
feel
file
fill
film
find
fine
fire
firm
fish
five
flag
flat
flow
fold
folk
food
foot
fork
form
four
free
frog
from
fuel
full
fund
gain
game
gang
gate
gift
girl
give
glad
goal
gold
golf
good
grab
grey
grow
hair
half
hall
hand
hang
hard
harm
hate
have
head
hear
heat
heel
hell
help
here
hero
hers
hide
high
hill
hire
hold
hole
holy
home
hope
host
hour
huge
hunt
hurt
idea
inch
into
iron
item
jazz
join
joke
jump
just
keen
keep
kick
kill
kind
king
kiss
knee
know
lack
lady
lake
lamp
land
last
late
lazy
lead
leaf
lean
left
lend
less
life
lift
like
line
link
lion
list
live
load
loan
lock
long
look
lord
lose
loss
lost
loud
love
luck
lung
mail
main
make
male
mall
many
mark
mass
meal
mean
meat
meet
melt
menu
mess
mild
mile
milk
mind
mine
miss
mood
moon
more
most
move
much
must
nail
name
near
neat
neck
need
news
next
nice
nine
none
nose
note
obey
once
only
onto
open
ours
oven
over
pace
pack
page
pain
pair
pale
park
part
pass
past
path
pick
pile
pink
pipe
plan
play
plot
plus
poem
poet
pool
poor
port
pose
post
pour
pray
pull
pure
push
quit
race
rain
rank
rare
rate
read
real
rely
rent
rest
rice
rich
ride
ring
rise
risk
road
rock
role
roll
roof
room
root
rope
rude
rule
rush
safe
sail
sale
salt
same
sand
save
scan
seat
seed
seek
seem
self
sell
send
ship
shoe
shop
shot
show
shut
sick
side
sign
silk
sing
sink
site
size
skin
slip
slow
snow
soap
sock
soft
soil
some
song
soon
sort
soul
soup
spot
star
stay
step
stop
such
suit
sure
swim
tail
take
tale
talk
tall
tank
tape
task
taxi
team
tear
tell
tend
tent
term
test
text
than
that
them
then
they
thin
this
thus
tidy
till
time
tiny
tone
tool
tour
town
tree
trip
true
tube
tune
turn
twin
type
tyre
ugly
unit
upon
urge
used
user
vary
vast
very
view
vote
wage
wait
wake
walk
wall
want
warm
warn
wash
wave
weak
wear
week
well
west
what
when
whom
wide
wife
wild
will
wind
wine
wing
wire
wise
wish
with
wood
wool
word
work
wrap
yard
yeah
year
your
zero
zone
acid
acre
aide
ally
amid
auto
bail
bare
bass
beam
bias
bind
bold
boom
buck
bulk
cave
chop
clip
cope
coup
cult
cute
dare
dawn
deck
deed
deem
defy
dive
dose
dual
dull
dumb
dump
ease
echo
exit
fade
fake
fame
fare
fate
feat
flaw
flee
fond
fool
gaze
gear
gene
grid
grin
grip
hail
halt
heal
herb
hint
hook
horn
icon
info
jail
jury
lane
lawn
leak
leap
lens
limb
logo
loom
loop
mask
mate
memo
mere
mill
mode
monk
myth
nest
noon
norm
odds
oral
palm
peak
peer
pill
pity
plea
plug
pole
poll
pond
prey
pump
punk
rage
raid
rail
rape
rear
riot
rose
ruin
sack
sake
seal
sexy
shed
sigh
skip
slam
slap
slot
snap
soak
soar
sole
solo
spam
span
spin
stab
stem
stir
stun
suck
tide
toll
toss
trap
trio
vein
vice
visa
ward
weed
whip
wipe
worm
yell
about
above
actor
adapt
admit
adopt
adult
after
again
agent
agree
ahead
alarm
album
alive
allow
alone
along
alter
among
anger
angle
angry
ankle
annoy
apart
apple
apply
argue
arise
armed
avoid
award
aware
awful
badly
based
basic
basis
beach
begin
being
below
birth
black
blame
blank
blind
block
blood
board
bored
brain
brand
brave
bread
break
bride
brief
bring
broad
brown
brush
build
bunch
cable
carry
catch
cause
chain
chair
chart
cheap
cheat
check
chest
chief
child
civil
claim
class
clean
clear
click
climb
clock
close
cloth
cloud
coach
coast
could
count
court
cover
crash
crazy
cream
crime
cross
crowd
cruel
curly
curve
cycle
daily
dance
death
delay
depth
diary
dirty
doubt
dozen
draft
drama
dream
dress
drink
drive
drunk
early
earth
eight
elect
email
empty
enemy
enjoy
enter
entry
equal
error
essay
event
every
exact
exist
extra
faith
false
fancy
fault
fence
field
fifth
fifty
fight
final
first
fixed
flame
flash
float
flood
floor
flour
focus
force
forty
found
frame
fresh
front
fruit
fully
funny
genre
ghost
giant
glass
glove
goods
grade
grain
grand
grant
grass
great
green
greet
group
guard
guess
guest
guide
habit
happy
heart
heavy
hello
hobby
horse
hotel
house
human
hurry
ideal
image
imply
inner
issue
jeans
judge
juice
knife
knock
label
large
later
laugh
layer
learn
least
leave
legal
lemon
level
light
limit
local
loose
lorry
lower
lucky
lunch
magic
major
marry
match
maths
maybe
means
media
metal
metre
might
minor
mixed
model
money
month
moral
motor
mount
mouse
mouth
movie
music
nerve
never
night
noise
noisy
north
novel
nurse
occur
ocean
offer
often
onion
order
organ
other
ought
outer
owner
paint
panel
pants
paper
party
peace
penny
phase
phone
photo
piano
piece
pilot
pitch
place
plain
plane
plant
plate
point
pound
power
press
price
prime
print
prize
proof
proud
prove
pupil
queen
queue
quick
quiet
quite
quote
radio
raise
range
rapid
reach
react
ready
refer
relax
reply
right
river
robot
rough
round
route
royal
rugby
rural
sadly
salad
sauce
scale
scary
scene
score
sense
serve
seven
shade
shake
shall
shame
shape
share
sharp
sheep
sheet
shelf
shell
shift
shine
shiny
shirt
shock
shoot
short
shout
sight
silly
since
sixty
skill
skirt
slave
sleep
slice
slide
slope
small
smart
smell
smile
smoke
snake
solar
solid
solve
sorry
sound
south
space
speak
speed
spell
spend
spicy
split
spoon
sport
staff
stage
stair
stamp
stand
stare
start
state
steal
steel
steep
stick
stiff
still
stock
stone
store
storm
story
study
stuff
style
sugar
swear
sweep
sweet
table
taste
teach
thank
their
theme
there
thick
thief
thing
think
third
three
throw
tight
tired
title
today
tooth
topic
total
touch
tough
towel
tower
track
trade
train
treat
trend
trial
trick
truck
truly
trust
truth
twice
uncle
under
union
until
upper
upset
urban
usual
value
venue
video
virus
visit
vital
voice
waste
watch
water
weigh
wheel
where
which
while
white
whole
whose
woman
world
worry
worse
worst
worth
would
wound
write
wrong
young
yours
youth
abuse
acute
alert
alien
align
alike
amend
angel
arena
array
arrow
aside
asset
audio
audit
await
badge
beast
bench
blade
blast
bleed
blend
bless
boast
bonus
boost
bound
breed
brick
buddy
burst
cabin
canal
cargo
carve
cater
cease
chaos
charm
chase
cheek
cheer
choir
chunk
civic
clash
clerk
cliff
cling
comic
crack
craft
crawl
creep
crown
crude
crush
dairy
debut
demon
dense
devil
donor
drain
drift
drown
eager
elbow
elite
embed
enact
enrol
ensue
equip
erect
erupt
ethic
evoke
exert
exile
fatal
fever
fibre
fleet
flesh
fluid
forge
forth
forum
fraud
globe
glory
grace
grasp
grave
grief
grind
guilt
handy
harsh
haunt
hence
idiot
incur
index
infer
input
irony
joint
laser
litre
liver
lobby
logic
loyal
lyric
march
mayor
medal
mercy
merge
merit
midst
miner
naked
nasty
naval
newly
niche
noble
opera
panic
patch
pause
plead
pride
prior
probe
pulse
punch
query
quest
quota
radar
rally
ratio
realm
rebel
reign
renew
rifle
risky
rival
saint
scare
scope
screw
seize
sheer
shore
shrug
skull
slash
smash
spare
spark
spill
spine
spite
spoil
squad
stake
stall
stark
steam
steer
strip
suite
super
surge
swing
sword
teens
tempt
terms
theft
thumb
tonne
toxic
trace
trail
trait
tribe
troop
twist
unify
unite
unity
usage
vague
valid
verse
vocal
weave
weird
wheat
widen
widow
width
wrist
yield
abroad
accept
access
accuse
across
action
active
actual
admire
advice
advise
affair
affect
afford
afraid
agency
agenda
almost
always
amazed
amount
animal
annual
answer
anyone
anyway
appeal
appear
around
arrest
arrive
artist
asleep
aspect
assess
assist
assume
attach
attack
attend
author
autumn
banana
battle
beauty
become
before
behave
behind
belief
belong
better
beyond
bitter
blonde
border
boring
borrow
bother
bottle
bottom
branch
breast
breath
bridge
bright
broken
bubble
budget
bullet
butter
button
camera
campus
cancel
cancer
cannot
career
carpet
carrot
castle
centre
chance
change
charge
cheese
choice
choose
church
cinema
circle
clause
clever
client
closed
coffee
colour
column
comedy
commit
common
cooker
corner
cotton
county
couple
course
cousin
create
credit
crisis
critic
curved
custom
damage
dancer
danger
debate
decade
decent
decide
deeply
defeat
defend
define
degree
demand
depend
desert
design
desire
detail
detect
device
dinner
direct
divide
doctor
dollar
donate
double
driver
during
easily
editor
effect
effort
eighty
either
eleven
emerge
employ
enable
ending
energy
engage
engine
enough
ensure
entire
escape
estate
except
excuse
expand
expect
expert
export
expose
extend
extent
factor
fairly
family
famous
farmer
fasten
father
favour
fellow
female
figure
finger
finish
flight
flower
flying
follow
forest
forget
formal
former
fourth
freeze
fridge
friend
frozen
future
garage
garden
gather
gentle
global
govern
ground
growth
guilty
guitar
handle
happen
hardly
health
heaven
height
highly
hockey
hollow
honest
honour
horror
humour
hungry
ignore
impact
import
impose
income
indeed
indoor
inform
injure
injury
insect
inside
insist
intend
invent
invest
invite
island
itself
jacket
junior
labour
laptop
latest
launch
lawyer
leader
league
length
lesson
letter
likely
liquid
listen
little
lively
living
locate
lonely
loudly
lovely
luxury
mainly
manage
manner
market
master
matter
medium
member
memory
mental
method
middle
minute
mirror
mobile
modern
modify
moment
monkey
mostly
mother
murder
muscle
museum
myself
narrow
nation
native
nature
nearly
needle
ninety
nobody
normal
notice
notion
number
object
obtain
offend
office
online
oppose
option
orange
origin
palace
parent
partly
pencil
people
pepper
period
permit
person
petrol
phrase
planet
player
please
plenty
pocket
poetry
poison
police
policy
polite
poster
potato
powder
praise
prayer
prefer
pretty
priest
prince
prison
profit
proper
public
punish
purple
pursue
racing
rarely
rather
reader
really
reason
recall
recent
recipe
record
reduce
refuse
regard
region
regret
reject
relate
relief
remain
remark
remind
remote
remove
repair
repeat
report
rescue
resist
resort
result
retain
retire
return
reveal
review
revise
reward
rhythm
rubber
runner
safety
sailor
salary
sample
saving
scared
scheme
school
scream
screen
script
search
season
second
secret
sector
secure
select
senior
series
settle
severe
sexual
shadow
should
shower
signal
silent
silver
simple
simply
singer
single
sister
skiing
slight
slowly
smooth
soccer
social
source
speech
spider
spirit
spoken
spread
spring
square
stable
statue
status
steady
sticky
stream
street
stress
strict
strike
string
strong
studio
stupid
submit
sudden
suffer
summer
supply
surely
survey
switch
symbol
system
tablet
talent
target
tennis
thanks
theirs
theory
thirty
though
threat
throat
ticket
toilet
tomato
tongue
travel
tunnel
twelve
twenty
unable
unfair
unique
united
unless
unlike
update
useful
valley
victim
viewer
vision
visual
volume
waiter
wealth
weapon
weight
widely
window
winner
winter
within
wonder
wooden
worker
writer
yellow
absent
absorb
absurd
accent
adhere
adjust
albeit
allege
anchor
aspire
assert
assign
assure
asylum
attain
backup
ballet
ballot
banner
barely
barrel
basket
behalf
beside
betray
bishop
bounce
breach
brutal
buffer
burden
burial
candle
canvas
carbon
casino
casual
cattle
clinic
colony
combat
compel
comply
confer
convey
copper
costly
cruise
deadly
dealer
debris
defect
delete
denial
depart
depict
deploy
deputy
derive
detain
devise
devote
differ
divert
divine
domain
embark
embody
empire
endure
enrich
entity
ethnic
evolve
exceed
excess
exotic
expire
fabric
failed
fierce
filter
firmly
flawed
forbid
format
fossil
foster
freely
fulfil
gallon
gaming
gender
genius
glance
golden
hatred
hazard
helmet
hidden
humble
hunger
immune
induce
infant
infect
inject
inmate
insert
insult
intact
intake
intent
invade
invoke
ironic
kidnap
kidney
ladder
lately
latter
layout
legacy
legend
lesser
lethal
liable
linear
linger
litter
making
margin
marine
marker
mature
melody
memoir
mentor
merely
merger
mining
misery
modest
mosque
motion
motive
moving
mutual
namely
nearby
notify
oblige
obsess
occupy
openly
outfit
outing
outlet
output
overly
oxygen
packet
parade
parish
pastor
patent
patrol
patron
pirate
pledge
plunge
preach
prompt
purely
puzzle
racial
racism
racist
random
rating
reckon
reform
refuge
regain
regime
remedy
render
rental
reside
resign
resume
retail
revive
ritual
robust
rocket
rotate
ruling
rumour
sacred
seeker
seldom
serial
shaped
shrink
sketch
slogan
solely
sphere
spouse
stance
starve
strain
strand
strive
stroke
subtle
suburb
summit
superb
tackle
tactic
temple
tenant
tender
tenure
terror
thesis
thread
thrive
timber
timely
timing
tissue
tragic
trauma
treaty
tribal
trophy
unfold
unveil
uphold
urgent
vacuum
vanish
varied
verbal
verify
versus
vessel
viable
virtue
voting
wander
weaken
weekly
whilst
wholly
wisdom
worthy
abandon
ability
account
achieve
acquire
actress
address
advance
against
airline
airport
alcohol
already
amazing
analyse
ancient
angrily
annoyed
another
anxious
anybody
approve
arrange
arrival
article
ashamed
athlete
attempt
attract
average
balance
barrier
battery
because
bedroom
believe
benefit
between
bicycle
billion
biology
biscuit
breathe
brother
camping
capable
capital
captain
capture
careful
cartoon
ceiling
central
century
certain
channel
chapter
charity
chicken
citizen
classic
clearly
climate
closely
clothes
collect
college
combine
comfort
command
comment
company
compare
compete
complex
concept
concern
concert
conduct
confirm
confuse
connect
consist
consume
contact
contain
content
contest
context
control
convert
cooking
correct
costume
cottage
council
country
courage
covered
crowded
crucial
culture
current
curtain
dancing
declare
decline
defence
delight
deliver
dentist
deserve
despite
destroy
develop
diagram
diamond
digital
discuss
disease
dislike
dismiss
display
drawing
dressed
driving
eastern
economy
edition
educate
elderly
element
emotion
engaged
enhance
enquiry
episode
equally
ethical
evening
exactly
examine
example
excited
expense
explain
explode
explore
express
extreme
factory
failure
farming
fashion
feather
feature
feeling
fiction
fifteen
finally
finance
finding
firstly
fishing
fitness
folding
foreign
forever
forgive
fortune
forward
freedom
funding
further
gallery
general
goodbye
happily
harmful
healthy
hearing
heating
heavily
helpful
herself
himself
history
holiday
housing
however
hundred
hunting
husband
illegal
illness
imagine
impress
improve
include
indoors
initial
injured
insight
inspire
install
instead
intense
involve
journal
journey
justice
justify
killing
kitchen
largely
leading
leather
lecture
leisure
library
licence
limited
located
logical
machine
manager
married
massive
maximum
meaning
measure
medical
meeting
mention
message
million
mineral
minimum
missing
mission
mistake
mixture
monitor
morning
musical
mystery
natural
neither
nervous
network
nothing
nowhere
nuclear
observe
obvious
offence
officer
opening
operate
opinion
opposed
outcome
outdoor
outline
outside
overall
package
painful
painter
parking
partner
passage
passion
patient
pattern
payment
pension
perfect
perform
perhaps
physics
picture
plastic
pleased
pointed
popular
possess
poverty
predict
prepare
present
pretend
prevent
primary
printer
privacy
private
problem
process
produce
product
profile
program
project
promise
promote
propose
protect
protest
provide
publish
purpose
qualify
quality
quarter
quickly
quietly
railway
rapidly
reading
reality
realize
receipt
receive
recover
recycle
reflect
regular
related
relaxed
release
replace
request
require
reserve
resolve
respect
respond
retired
routine
rubbish
running
sailing
satisfy
science
section
serious
servant
service
session
setting
seventy
several
shallow
shelter
shocked
silence
similar
sincere
singing
sixteen
smoking
society
soldier
someone
speaker
special
species
sponsor
stadium
station
stomach
strange
stretch
student
subject
succeed
success
suggest
summary
support
suppose
surface
surgery
survive
suspect
sweater
symptom
teacher
teenage
theatre
therapy
thirsty
thought
through
tonight
totally
tourism
tourist
towards
traffic
trainer
trouble
typical
unhappy
uniform
unknown
unusual
upwards
usually
variety
various
vehicle
version
victory
village
violent
virtual
visitor
vitamin
warning
washing
wealthy
weather
website
wedding
weekend
welcome
western
whereas
whether
whisper
willing
without
witness
working
worried
writing
written
abolish
absence
academy
accused
adverse
amateur
amusing
analogy
analyst
anxiety
apology
applaud
appoint
archive
artwork
assault
auction
awkward
backing
balloon
bargain
beloved
beneath
besides
bizarre
blanket
bombing
booking
briefly
broadly
browser
cabinet
caution
chamber
charter
chronic
circuit
clarify
clarity
closure
cluster
coastal
compile
compose
compute
conceal
concede
condemn
confess
confine
conquer
consent
consult
contend
convict
corrupt
counter
creator
crystal
curious
custody
cutting
cynical
default
deficit
density
deposit
deprive
descend
descent
desktop
dictate
dignity
dilemma
discard
dispose
dispute
disrupt
distant
distort
disturb
diverse
divorce
drought
dynamic
elegant
elevate
embassy
embrace
empower
endless
endorse
enforce
enquire
entitle
essence
eternal
evident
exclude
execute
exhibit
exploit
extract
faction
faculty
fantasy
federal
feeding
firearm
fixture
flavour
footage
formula
founder
fragile
frankly
funeral
furious
generic
genetic
genuine
gesture
glimpse
graphic
gravity
greatly
grocery
habitat
halfway
handful
harbour
harmony
harvest
highway
honesty
hopeful
horizon
hostage
hostile
imagery
immense
indulge
inflict
inherit
inhibit
insider
inspect
instant
interim
isolate
kingdom
landing
lawsuit
leaflet
lengthy
lesbian
liberal
liberty
license
listing
lottery
loyalty
magical
mandate
martial
militia
minimal
miracle
missile
monster
monthly
neglect
neutral
nominee
notable
notably
nursery
nursing
obesity
ongoing
optical
organic
outlook
outrage
oversee
partial
passing
passive
pathway
peasant
penalty
persist
pioneer
portion
portray
precede
precise
premier
premise
premium
preside
presume
prevail
proceed
protein
provoke
pursuit
radical
ranking
readily
rebuild
recount
recruit
referee
refugee
refusal
relieve
remains
removal
residue
restore
retreat
revenge
revenue
reverse
revival
robbery
romance
roughly
scandal
scholar
scratch
secular
segment
seminar
senator
settler
shatter
shortly
sibling
skilled
slavery
somehow
specify
squeeze
storage
stumble
subsidy
suicide
supreme
surgeon
surplus
suspend
sustain
swallow
tension
terrain
terrify
testify
testing
texture
thereby
tighten
tobacco
torture
trading
tragedy
trailer
transit
tribute
trigger
triumph
trustee
tsunami
tuition
turnout
undergo
upgrade
useless
utility
utilize
utterly
venture
verdict
veteran
vibrant
vicious
violate
visible
warfare
warming
warrant
warrior
welfare
whereby
whoever
workout
worship
absolute
academic
accident
accurate
activity
actually
addition
advanced
aircraft
although
ambition
analysis
announce
annoying
anything
anywhere
apparent
approach
approval
argument
artistic
attitude
audience
bacteria
baseball
bathroom
birthday
building
business
campaign
capacity
careless
category
ceremony
chairman
champion
cheerful
chemical
clothing
collapse
coloured
commonly
complain
complete
computer
conclude
conflict
confused
consider
constant
consumer
continue
contract
contrast
convince
creation
creative
creature
criminal
critical
cultural
cupboard
currency
customer
daughter
decision
decorate
decrease
definite
delivery
describe
designer
detailed
dialogue
directly
director
disagree
disaster
discount
discover
distance
district
division
divorced
document
domestic
dominate
download
dramatic
economic
educated
eighteen
election
electric
elephant
emphasis
employee
employer
engineer
enormous
entirely
entrance
estimate
evaluate
everyday
everyone
evidence
exchange
exciting
exercise
expected
external
facility
familiar
feedback
festival
fighting
flexible
football
fourteen
friendly
frighten
function
generate
generous
graduate
grateful
headache
headline
hesitate
historic
homework
horrible
hospital
humorous
identify
identity
incident
included
increase
indicate
indirect
industry
informal
innocent
instance
intended
interest
internal
internet
involved
keyboard
language
laughter
learning
listener
location
magazine
maintain
majority
marriage
matching
material
medicine
midnight
military
minister
minority
mountain
movement
multiple
multiply
musician
national
negative
nineteen
normally
northern
numerous
occasion
official
opponent
opposite
ordinary
organize
original
outdoors
painting
passport
peaceful
personal
persuade
physical
planning
platform
pleasant
pleasure
politics
portrait
position
positive
possible
possibly
powerful
practice
practise
pregnant
prepared
presence
preserve
pressure
previous
princess
printing
priority
prisoner
probably
producer
progress
properly
property
proposal
prospect
purchase
quantity
question
reaction
recently
regional
register
relation
relative
relaxing
relevant
reliable
religion
remember
repeated
reporter
research
resident
resource
response
romantic
sandwich
schedule
secondly
security
sensible
sentence
separate
sequence
shooting
shopping
shoulder
slightly
software
solution
somebody
somewhat
southern
specific
spelling
spending
standard
straight
stranger
strategy
strength
strongly
struggle
suddenly
suitable
surprise
surround
swimming
sympathy
talented
teaching
teenager
terrible
thinking
thirteen
thousand
threaten
together
tomorrow
training
transfer
tropical
trousers
umbrella
universe
unlikely
upstairs
vacation
valuable
violence
weakness
whatever
whenever
wherever
wildlife
yourself
abortion
abstract
accuracy
activate
activist
adequate
adjacent
adoption
advocate
alliance
allocate
ancestor
annually
appetite
arguably
assemble
assembly
atrocity
attorney
autonomy
backdrop
balanced
basement
blessing
boundary
carriage
casualty
cautious
cemetery
charming
civilian
classify
cleaning
clinical
cocktail
coincide
colonial
commence
commerce
composer
compound
comprise
conceive
concrete
confront
conserve
contempt
contrary
corridor
courtesy
coverage
credible
critique
damaging
darkness
database
deadline
decisive
defender
delegate
delicate
denounce
diagnose
dictator
diminish
diplomat
disabled
disclose
disorder
displace
disposal
dissolve
distinct
distract
distress
doctrine
dominant
donation
downtown
duration
earnings
educator
eligible
emission
engaging
envelope
epidemic
equality
equation
escalate
evacuate
explicit
exposure
fabulous
fairness
feminist
firework
flourish
forecast
formerly
fraction
fragment
frequent
gambling
genocide
glorious
goodness
gorgeous
governor
graphics
guidance
handling
hardware
heighten
heritage
homeland
homeless
humanity
hydrogen
ideology
illusion
imminent
imprison
inclined
infamous
inherent
initiate
instinct
instruct
integral
interact
interior
interval
intimate
invasion
investor
isolated
judicial
junction
landlord
landmark
lifelong
lifetime
lighting
likewise
literacy
literary
magnetic
mainland
manifest
marathon
marginal
massacre
maximize
meantime
mechanic
medieval
memorial
merchant
metaphor
militant
minimize
ministry
mobility
mobilize
moderate
momentum
monopoly
monument
morality
moreover
mortgage
motivate
motorist
nominate
nonsense
notebook
novelist
nowadays
observer
obstacle
offender
offering
operator
optimism
outbreak
outsider
overcome
overlook
overseas
overturn
parallel
parental
password
patience
peculiar
perceive
petition
pipeline
postpone
precious
predator
probable
proceeds
proclaim
profound
prohibit
protocol
province
rational
reassure
receiver
recovery
regulate
relieved
reminder
renowned
republic
resemble
restrict
retrieve
revision
rhetoric
rotation
sanction
scenario
scrutiny
severely
shipping
shocking
shortage
simulate
situated
sometime
specimen
spectrum
sporting
standing
steadily
stimulus
strictly
striking
stunning
suburban
superior
suppress
surgical
survival
survivor
symbolic
syndrome
tactical
taxpayer
tendency
terminal
terribly
terrific
textbook
theology
thorough
thrilled
tolerate
transmit
treasure
tribunal
trillion
troubled
turnover
ultimate
upcoming
validity
variable
vertical
villager
withdraw
workshop
accompany
according
advantage
adventure
advertise
afternoon
agreement
alcoholic
ambitious
apartment
apologize
architect
assistant
associate
attention
authority
available
backwards
basically
beautiful
beginning
behaviour
boyfriend
breakfast
breathing
brilliant
broadcast
calculate
candidate
carefully
celebrate
celebrity
certainly
challenge
character
chemistry
childhood
chocolate
cigarette
classical
classroom
colleague
committee
community
complaint
component
concerned
condition
confident
confusing
connected
conscious
construct
container
continent
convinced
corporate
correctly
criterion
criticism
criticize
currently
dangerous
delicious
delighted
departure
depressed
desperate
detective
determine
different
difficult
direction
disappear
discovery
dishonest
downwards
education
effective
efficient
elsewhere
emergency
emotional
emphasize
encounter
encourage
entertain
equipment
essential
establish
everybody
excellent
executive
existence
expensive
explosion
extremely
fantastic
favourite
financial
following
frequency
furniture
generally
gentleman
geography
gradually
guarantee
happiness
highlight
household
hurricane
imaginary
immediate
immigrant
impatient
important
impressed
including
infection
influence
initially
institute
insurance
intention
interpret
interrupt
interview
introduce
invention
jewellery
judgement
kilometre
knowledge
landscape
lifestyle
marketing
meanwhile
narrative
naturally
necessary
neighbour
newspaper
nightmare
objective
obviously
offensive
operation
organized
organizer
otherwise
ourselves
paragraph
passenger
perfectly
permanent
poisonous
policeman
political
pollution
potential
practical
president
principle
procedure
professor
programme
pronounce
qualified
quotation
realistic
reception
recognize
recommend
recording
reduction
reference
regularly
religious
represent
satellite
satisfied
scientist
sculpture
secondary
secretary
selection
sensitive
seriously
seventeen
similarly
situation
something
sometimes
somewhere
spiritual
statement
statistic
structure
substance
summarize
supporter
surprised
technical
technique
telephone
temporary
therefore
tradition
transform
translate
transport
traveller
treatment
typically
underwear
vegetable
volunteer
wonderful
worldwide
yesterday
abundance
addiction
admission
aesthetic
affection
aftermath
alignment
allegedly
allowance
alongside
aluminium
ambulance
amendment
animation
anonymous
apparatus
appealing
applicant
arbitrary
assertion
assurance
attribute
authentic
authorize
automatic
awareness
benchmark
biography
breakdown
broadband
catalogue
certainty
circulate
coalition
cognitive
collector
collision
colourful
columnist
commander
commodity
communist
companion
competent
confusion
consensus
contender
cooperate
copyright
correlate
countless
cultivate
curiosity
dedicated
defensive
democracy
dependent
designate
desirable
detection
detention
devastate
diagnosis
dimension
directory
discharge
discourse
dismissal
diversity
dominance
economics
economist
editorial
elaborate
electoral
eliminate
emergence
empirical
encompass
endeavour
enjoyable
evolution
exception
excessive
exclusion
exclusive
execution
expansion
expertise
explosive
extension
extensive
extremist
foreigner
formation
formulate
fortunate
framework
franchise
gathering
genuinely
guerrilla
guideline
hierarchy
hilarious
historian
hopefully
hostility
identical
ignorance
implement
inability
incentive
incidence
inclusion
incorrect
indicator
inflation
injection
injustice
insertion
inspector
instantly
integrate
integrity
intensify
intensity
intensive
interface
interfere
intervene
invisible
isolation
legendary
literally
machinery
magnitude
mandatory
mechanism
memorable
migration
miserable
municipal
necessity
negotiate
notorious
nutrition
objection
obsession
offspring
orchestra
originate
overnight
overwhelm
ownership
parameter
partially
personnel
physician
placement
portfolio
precedent
precisely
precision
pregnancy
prejudice
prescribe
presently
primarily
principal
privilege
processor
prominent
promising
promotion
prosecute
protester
provision
publicity
radiation
reasoning
rebellion
recession
recipient
regulator
reinforce
rejection
relevance
reluctant
remainder
reporting
reproduce
residence`.split('\n');