<template>
  <nav class="bg-tertiary2">
    <div class="max-w-7xl mx-auto sm:px-6 lg:px-8 h-16 flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
      <div class="shrink-0 flex items-center">
        <img src="/logo.svg" class="h-8" alt="Logo"/>
        <span class="font-headline font-bold text-primary ml-2 pb-px">CRYPTOMATOR HUB</span>
      </div>
    </div>
  </nav>

  <div class="max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 flex justify-center">
    <div class="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6 text-center sm:w-full sm:max-w-lg">
      <div class="flex justify-center mb-3 sm:mb-5">
        <img src="/logo.svg" class="h-12" alt="Logo" aria-hidden="true" />
      </div>
      <h1 class="text-2xl leading-6 font-medium text-gray-900">
        Unlock failed
      </h1>
      <p class="mt-6 text-sm text-gray-500">
        Your unlock failed unexpectedly. Please try again.
      </p>
    </div>
  </div>
</template>